import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  padding: 2rem 0;
  border-bottom: 4px solid #00b0f0;

  @media (min-width: 1080px) {
    flex-direction: row;
  }
`;

const StyledHeaderTitle = styled.h1`
  margin: 0;
  text-align: center;
  font-size: 2rem;
`;

const Wrapper = ({ children }) => {
  return (
    <StyledHeader>
      <img src={createImageCdnLink("Idin-Logo.png")} />
      <StyledHeaderTitle>{children}</StyledHeaderTitle>
    </StyledHeader>
  );
};

export default Wrapper;
