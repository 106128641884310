import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled, { css } from "styled-components";

import {
  PageWrapper,
  PageHeader,
  PageParagraph,
  PrimaryButton,
  BackButton,
} from "components/shared";

const StyledSelectLabel = styled.label`
  display: block;
  margin-bottom: 20px;
`;

const sharedControlStyles = css`
  height: 64px;
  border-radius: 0.5rem;
  transition: box-shadow 0.35s;

  &:focus {
    border-color: #00b0f0;
    box-shadow: 0 0 0 3px #b5ebff;
  }
`;

const StyledSelectWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 28px;
    right: 20px;
    width: 1px;
    height: 1px;
    border: 7px solid black;
    border-bottom: 7px solid transparent;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
  }

  @media (min-width: 1080px) {
    width: 500px;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 64px;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 2px solid #e0e0e0;
  background: transparent;
  appearance: none;
  outline: none;

  ${sharedControlStyles};
`;

const Wrapper = () => {
  const [banks, setBanks] = useState([]);
  const [activeBank, setActiveBank] = useState();
  const userId = useLocation()?.search?.replace("?user_id=", "");

  const handleChange = (e) => {
    setActiveBank(e.target.value);
  };

  const handleSubmit = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/toto-age-confirmation/request-data`,
      {
        issuer_id: activeBank,
        user_id: userId,
      },
      {
        headers: {
          Accept: "application/json",
          "x-app-id": "Low6",
        },
      }
    );

    if (window.location !== window.parent.location) {
      window.parent.location.href = res?.data?.redirect_url;
    } else {
      window.location.href = res?.data?.redirect_url;
    }  
  };

  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/toto-age-confirmation/banks`,
        {
          headers: {
            Accept: "application/json",
            "x-app-id": "Low6",
          },
        }
      );
      setBanks(res?.data?.banks);
    })();
  }, []);

  return (
    <PageWrapper>
      <PageHeader>Leeftijd bevestigen met iDIN</PageHeader>
      <PageParagraph>
        Met iDIN kunt u zich online bij een bank of instelling identificeren en
        uw gegevens verifiëren. Dit is gemakkelijk, betrouwbaar en veilig via de
        inlogmethode van uw bank.
      </PageParagraph>
      <StyledSelectLabel>Selecteer uw bank:</StyledSelectLabel>
      <StyledSelectWrapper>
        <StyledSelect onChange={handleChange}>
          <optgroup>
            <option disabled selected>
              Kies een optie
            </option>
            {banks?.map((bank) => (
              <option key={bank?.issuer_id} value={bank?.issuer_id}>
                {bank?.issuer_name}
              </option>
            ))}
          </optgroup>
        </StyledSelect>
      </StyledSelectWrapper>
      <PrimaryButton onClick={handleSubmit} disabled={!activeBank}>
        LOG IN MET IDIN
      </PrimaryButton>
      {/* <BackButton /> */}
    </PageWrapper>
  );
};

export default Wrapper;
