import styled from "styled-components";

const StyledParagraph = styled.p`
  line-height: 1.4;
  margin-bottom: 100px;

  & a {
    color: inherit;
  }
`;

const Wrapper = ({ children }) => {
  return <StyledParagraph>{children}</StyledParagraph>;
};

export default Wrapper;
