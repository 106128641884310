import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "components/feature/Home";
import Success from "components/feature/Success";
import Failure from "components/feature/Failure";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/succes" component={Success} />
        <Route exact path="/storing" component={Failure} />
      </Switch>
    </Router>
  );
}

export default App;
