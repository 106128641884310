import styled from "styled-components";

const StyledWrapper = styled.div`
  margin: 0 1rem;
  padding: 0 0 100px;

  @media (min-width: 1080px) {
    margin: 0 auto;
    width: 980px;
  }
`;

const Wrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

export default Wrapper;
