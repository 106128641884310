import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const sharedStyles = css`
  display: grid;
  place-items: center;
  width: 100%;
  margin-bottom: 100px;
  height: 64px;
  border-radius: 0.5rem;
  border: 0;
  background: #00b0f0;
  color: #f7f7f7;
  font-weight: 700;
  text-decoration: none;

  transition: box-shadow 0.35s;

  &:focus {
    border-color: #00b0f0;
    box-shadow: 0 0 0 3px #b5ebff;
  }

  &:disabled {
    background: #cdd4d7;
    color: #aebcc1;
  }

  @media (min-width: 1080px) {
    width: 280px;
  }
`;

const StyledButton = styled.button`
  ${sharedStyles};
`;

const StyledLink = styled(Link)`
  ${sharedStyles};
`;

const Wrapper = ({ children, onClick, disabled, to }) => {
  return to ? (
    <StyledLink to={to}>{children}</StyledLink>
  ) : (
    <StyledButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default Wrapper;
