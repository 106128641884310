import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  PageHeader,
  PageParagraph,
  PageWrapper,
  PrimaryButton,
  BackButton,
} from "components/shared";

const Wrapper = () => {
  const userId = useLocation()?.search?.replace("?user_id=", "");

  return (
    <PageWrapper>
      <PageHeader>Er is een fout opgetreden bij uw iDIN aanvraag​</PageHeader>
      <PageParagraph>
        Kan niet verifiëren. Klik OPNIEUW om het opnieuw te proberen.
        {/* Niet in staat om te verifiëren. Neem contact op met de klantenservice
        via <a href="mailto:info@score4.nl">info@score4.nl</a> of klik op
        OPNIEUW om het opnieuw te proberen. */}
      </PageParagraph>

      <PrimaryButton to={`/?user_id=${userId}`}>OPNIEUW</PrimaryButton>
      {/* <BackButton /> */}
    </PageWrapper>
  );
};

export default Wrapper;
