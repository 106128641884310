import styled from "styled-components";

const StyledBackButton = styled.a`
  display: grid;
  place-items: center;
  height: 50px;
  padding: 0 1rem;
  border-radius: 6px;
  border: 2px solid #00b0f0;
  color: #00b0f0;
  background: transparent;
  font-size: 1.175rem;
  font-weight: 700;
  text-decoration: none;

  transition: box-shadow 0.35s;

  &:focus {
    box-shadow: 0 0 0 3px #b5ebff;
  }

  @media (min-width: 1080px) {
    width: 180px;
  }
`;

const Wrapper = () => {
  return (
    <StyledBackButton href={process.env.REACT_APP_URL_BACK}>
      {process.env.REACT_APP_URL_BACK_LABEL}
    </StyledBackButton>
  );
};

export default Wrapper;
