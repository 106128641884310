import styled, { css } from "styled-components";

import {
  PageHeader,
  PageWrapper,
  PrimaryButton,
  BackButton,
} from "components/shared";

const StyledAlert = styled.div`
  margin: 50px 0;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #edf6f1;
  color: #6fa383;
  border: 1px solid #b8eacc;
`;

const StyledSteps = styled.div``;

const StyledStep = styled.div`
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-right: 30px;
  height: 60px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 17px;
    height: 50%;
    width: 3px;
    background: #00b0f0;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &:first-child::before,
  &:last-child::after {
    display: none;
  }
`;

const StyledStepNumber = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
  font-size: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #00b0f0;
  color: #f7f7f7;
`;

const StyledParagraph = styled.p`
  margin-bottom: 40px;
`;

const StyledStepText = styled.div`
  font-size: 1rem;
`;

const Wrapper = () => {
  return (
    <PageWrapper>
      <PageHeader>Uw leeftijd is bevestigd met iDIN</PageHeader>

      <StyledAlert>
        <b>Opmerking:</b> voor een optimale werking kan het nodig zijn om de app
        of website éérst te sluiten en het spel daarna opnieuw te openen.​
      </StyledAlert>

      <StyledParagraph>
        Ga nu terug naar het spel om je prijs te claimen. Dit doe je in 4
        simpele stappen:
      </StyledParagraph>

      <StyledSteps>
        <StyledStep>
          <StyledStepNumber>1</StyledStepNumber>
          <StyledStepText>Open de game</StyledStepText>
        </StyledStep>
        <StyledStep>
          <StyledStepNumber>2</StyledStepNumber>
          <StyledStepText>Log in</StyledStepText>
        </StyledStep>
        <StyledStep>
          <StyledStepNumber>3</StyledStepNumber>
          <StyledStepText>Klik op 'Profiel'</StyledStepText>
        </StyledStep>
        <StyledStep>
          <StyledStepNumber>4</StyledStepNumber>
          <StyledStepText>Klik op 'Jouw score'</StyledStepText>
        </StyledStep>
      </StyledSteps>
    </PageWrapper>
  );
};

export default Wrapper;
